<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import tableData from "@/components/tablecomponent";
import Multiselect from "vue-multiselect";
import {
  required,
  // email,
  // minLength,
  // sameAs,
  // maxLength,
  // numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";
// import axios from 'axios';
/**
 * Advanced-table component
 */

export default {
  page: {
    title: "เชื่อมต่อบัญชีไลน์",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "เชื่อมต่อบัญชีไลน์",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
        },
        {
          text: "เชื่อมต่อบัญชีไลน์",
        },
        {
          text: "บันทึก",
        },
      ],

      useId: "",
      customer: "",
      rowsUser: [],
      localDataBranch: [],
      localData: [],
      overlayFlag: false,
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      total: "",
      rowData: [], //? Data ที่ต่อ API
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      // filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      selectedUsers: [],
      isSelectedAll: false,
      selectMode: "multi",
      selected: [],
      branchIdSearch: [],
      filter: [
        {
          outsourcingCode: "",
          brands: "",
          licensePlate: "",
        },
      ],
      osId: "",
      submit: false,

      optionsBrand: [],
      optionsVehicles: [],
      nameTh: "",
      nameEn: "",
      useCode: "",
      branchId: "",
      error: "กรุณากรอกข้อมูล",
    };
  },
  validations: {
    branchId: {
      required,
    },
    customer: {
      required,
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    //
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.rowData.length;
  },

  created() {
    this.getLocalData();
    this.getData();
  },
  methods: {
    sendFlex(){

      const bodyFormData = new FormData();
      bodyFormData.append("lineId", this.$route.params.lineId);

      useNetw
        .post("api/line-connect/send-message", {
          lineId: this.$route.params.lineId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data),
            appConfig.swal.type.success
          );
          
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    putLineConnect() {
      useNetw
        .put("/api/line-connect/put", {
          lineId: this.$route.params.lineId,
          cusId: this.customer.cusId,
          branchId: this.branchId.branchId

        })
        .then((response) => {
          // this.showAlert(response.data.message);
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push("/line-connect");
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    submitForm() {
      this.submit = true;
      this.$v.$touch();

      if (this.$v.$invalid != true) {
        // console.log("ok");
        this.putLineConnect();
      }
    },
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh == null ? '' : nameTh}  
      ${familyNameTh != null ? familyNameTh : ""}`;
    },
    getDataCus: function(name) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/customer", {
          params: {
            page: 1,
            perPage: 100,
            nameTh: name,
            "branchId[]": this.branchId.branchId,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getRemoveSelected: function() {
      this.isLoading = true;
      useNetw
        .get("api/service/customer", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]": this.branchId.branchId,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getCustomer: function() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/line-connect/show", {
          params: {
            lineId: this.$route.params.lineId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          // console.log(response.data.data);
          this.rowData = response.data.data;
          this.branchId = {
            branchId: response.data.data.pageId,
            nameTh: response.data.data.branchName,
          };
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.branchId = user.pageId;
      // console.log(this.localDataBranch);
    },
    handleSearch() {
      this.getData();
    },

    getData: function() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/line-connect/show", {
          params: {
            lineId: this.$route.params.lineId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          console.log(response.data.data);
          this.rowData = response.data.data;
          this.branchId = {
            branchId: response.data.data.pageId,
            nameTh: response.data.data.branchName,
          };
          if (response.data.data != null) {
            this.customer = {
              cusId: response.data.data.cusId,
              nameTh: response.data.data.cusName,
              familyNameTh: response.data.data.cusFamilyName,
            };
          } else {
            this.customer = "";
          }
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">ข้อมูลแพคเกจในระบบ</h4> -->
            <br />
            <!-- End search -->
            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <div class="row">
                <div class="col-md-6 text-center">
                  <b-avatar :src="rowData.pictureUrl" size="10rem"></b-avatar>
                  <br />
                  <h5 class="mt-3">ชื่อไลน์ : {{ rowData.firstname }}</h5>
                  ไอดีลูกค้า : {{ rowData.userId }} <br />
                  <span style="color:red">
                    ชื่อลูกค้าที่เชื่อมต่อ : {{ rowData.cusName }}
                    {{ rowData.cusFamilyName }}
                  </span>
                  <br />
                  ประจำสาขา : {{ rowData.branchName }}
                </div>
                <div class="col-md-6 text-center">
                  <div class="row">
                    <h4 style="color:red">แก้ไขการเชื่อมต่อ</h4>
                    <div class="col-md-12">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="branchId">สาขา:</label>
                        <multiselect
                          id="branchId"
                          v-model="branchId"
                          :options="localData"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                          :class="{
                            'is-invalid': submit && $v.branchId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                        >
                        <div
                          v-if="submit && $v.branchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.branchId.required">{{ error }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-3 position-relative">
                        <label for="validation">ชื่อลูกค้า:</label>
                        <multiselect
                          v-model="customer"
                          label="nameTh"
                          type="search"
                          :options="rowsUser"
                          :show-labels="false"
                          open-direction="bottom"
                          @search-change="getDataCus($event)"
                          @remove="getRemoveSelected"
                          :custom-label="customLabel"
                          placeholder=""
                          :class="{
                            'is-invalid': submit && $v.customer.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submit && $v.customer.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.customer.required">{{ error }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <button class="btn btn-success" @click="submitForm()">
                  บันทึก
                </button>
              </div>
              <div class="row">
                <button class="btn btn-danger" @click="sendFlex()">
                  ส่งข้อความ
                </button>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
